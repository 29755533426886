import { BASE_URL } from "../config";
import { getAuthToken } from "./tokenprovider";
import { getStartOfDay } from "./dateUtils";

const fetchCabinetNames = async (swapdata) =>{
    const token = getAuthToken()
    try {
        if(!token) throw new Error("No token present")

        const fetchpayment = swapdata.map( async(swap)=>{
            try {
                const response = await fetch(`${BASE_URL}/api/v1/cabinets/getbpoolcabinet?cabinetid=${swap.cabinet_id}`, {
                    method:"GET", 
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    }
                })
                const data = await response.json()
                return {...swap, cabinet_name:data?.cabinet?.display_name}
            } catch (error) {
                console.error(`Failed to fetch for cabinet_id ${swap.cabinet_id}: ${error.message}`);
                return { ...swap, cabinet_name: "" }; 
            }

        })
    
        const results = await Promise.all(fetchpayment)
        return results
    } catch (error) {
        console.log(error.message)
        return swapdata
    }

}

const fetchPaymentDetails = async(swapdata)=>{
    const token = getAuthToken()
    try {
        if(!token) throw new Error("No token present")

        const fetchpayment = swapdata.map( async(swap)=>{
            try {
                const starttime = getStartOfDay(swap?.createdAt)
                const response = await fetch(`${BASE_URL}/api/v1/payments?customerid=${swap?.customer_id}&startdate=${encodeURIComponent( starttime )}&enddate=${encodeURIComponent(swap?.createdAt)}`, {
                    method:"GET", 
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    }
                })
                const data = await response.json()
                return {...swap, payment_data:data?.PaymentsLog}
            } catch (error) {
                console.error(`Failed to fetch payment for cabinet_id ${swap.customer_id}: ${error.message}`);
                return { ...swap, payment_data:[] }; 
            }

        })
    
        const results = await Promise.all(fetchpayment)
        return results
    } catch (error) {
        console.log(error.message)
        return swapdata
    }
}


export const formatSwapData = async(data)=>{
    const swapDataWithCabinetNames = await fetchCabinetNames(data)
    const swapsWithPaymentData = await fetchPaymentDetails(swapDataWithCabinetNames)
    return swapsWithPaymentData
}

