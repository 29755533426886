import { Box } from '@mui/material';
import { formatDateString } from '../../../utils/dateUtils';

const SwapsPaymentCheck = ({payments}) => {

    const sucessfulPaymentDetails = payments.filter(x => x.payment_status === "success")[0]
  return (
        <Box >
            {sucessfulPaymentDetails
                ? `Paid ${Number(sucessfulPaymentDetails?.amount)} on ${formatDateString(sucessfulPaymentDetails?.createdAt)} with phone number ${sucessfulPaymentDetails?.phone}`
                : "No payment recorded at time of swap"
            }
        </Box>

  );
}

export default SwapsPaymentCheck