import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "../config";
import { getAuthToken } from "../utils/tokenprovider";

export const SwapsApi = createApi({
	reducerPath: "swapsApi",
	baseQuery: fetchBaseQuery({
		baseUrl: BASE_URL,
		prepareHeaders: (headers) => {
			const token = getAuthToken();

			if (token) {
				headers.set("authorization", `Bearer ${token}`);
			}

			return headers;
		},
	}),
	tagTypes: ["swaps"],
	endpoints: (builder) => ({
		getSwaps: builder.query({
			query: ({
				startdate,
				enddate,
				batid,
				cabinetid,
				customername,
				page,
				pagesize,
				swapstatus
			}) => ({
				method: "GET",
				url: "/api/v1/swaps",
				params: {
					cabinetid,
					startdate,
					enddate,
					bathist: batid,
					customername,
					page: page + 1,
					limit: pagesize,
					swapstatus
				},
			}),
			providesTags: ["swaps"],
			transformResponse: (response) =>{
				const data = response?.SwapsLog
				const tranformedData = data?.map((swapentry) => {
					return {...swapentry, payment_data: null, cabinet_name:null}
				})	
				return {...response, SwapsLog:tranformedData }
			}
		}),
		getHourSwapChart: builder.query({
			query: () => ({
				method: "GET",
				url: "/api/v1/swaps/hourswapschart",
			}),
		}),
		getWeekSwapsChart: builder.query({
			query: () => ({
				method: "GET",
				url: "/api/v1/swaps/weekswapschart",
			}),
		}),
		getRollingSwapChart: builder.query({
			query: () => ({
				method: "GET",
				url: "/api/v1/swaps/rollingdayswapschart",
			}),
		}),
	}),
});

export const {
	useGetSwapsQuery,
	useGetHourSwapChartQuery,
	useGetWeekSwapsChartQuery,
	useGetRollingSwapChartQuery,
} = SwapsApi;
