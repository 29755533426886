import { Box, useTheme, Button, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../../../theme";
import { useGetPaymentsQuery } from "../../../../state_management/payments";
import { useState } from "react";
import { formatDateString } from "../../../../utils/dateUtils";
import PaymentGridToolbar from "./PaymentGridToolbar";

const PaymentGrid = () => {
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);

	const [page, setPage] = useState(0);
	const [pagesize, setPageSize] = useState(50);
	const [startdate, setStartDate] = useState("");
	const [enddate, setEndDate] = useState("");
	const [customerphone, setCustomerPhone] = useState("");
	const [paymentid, setPaymentId] = useState("");
	const [status, setStatus] = useState("success");
	const [customerid, setCustomerId] = useState("");
	const [customername, setCustomerName] = useState("");
	const [amount, setAmount] = useState("");

	const { data, isLoading } = useGetPaymentsQuery({
		page,
		pagesize,
		startdate,
		enddate,
		customerphone,
		paymentid,
		status,
		customerid,
		customername,
		amount,
	});

	const columns = [
		{
			field: "customer_name",
			headerName: "CUSTOMER",
			flex: 0.6,
			renderCell: ({ row: { customer_id, customer_name } }) => {
				return (
					<Button
						variant="Primary"
						sx={{
							backgroundColor: colors.blueAccent[500],
							color: colors.blueAccent[900],
						}}
						onClick={() => console.log(customer_id)}
					>
						{customer_name}
					</Button>
				);
			},
		},
		{
			field: "phone",
			headerName: "Phone",
			flex: 0.4,
		},
		{
			field: "createdAt",
			headerName: "TIME",
			flex: 0.6,
			cellClassName: "name-column--cell",
			align: "center",
			headerAlign: "center",
			renderCell: ({ row: { createdAt } }) => {
				return `${formatDateString(createdAt)}`;
			},
		},
		{
			field: "amount",
			headerName: "Ammount",
			flex: 0.4,
			renderCell:({row: {amount}}) =>{
				return <>{Number(amount)}</>
			}
		},
		{
			field: "customer_id",
			headerName: "Customer id",
			flex: 0.6,
		},
		{
			field: "payment_id",
			headerName: "Payment id",
			flex: 0.6,
		},
		{
			field: "payment_status",
			headerName: "Payment Status",
			flex: 0.6,
			renderCell: ({ row: { payment_status, description } }) => {
				return (
					<Typography>
						{payment_status === "success"
							? "Success"
							: `${payment_status} : ${description}`}
					</Typography>
				);
			},
		},
	];

	return (
		<Box m="20px" mt="100px">
			<Typography
				variant="h3"
				fontWeight="bold"
				mb={"10px"}
				color={colors.greenAccent[500]}
			>
				Payments
			</Typography>
			<Box
				height="75vh"
				sx={{
					"& .MuiDataGrid-root": { border: "none" },
					"& .MuiDataGrid-cell": { borderBottom: "none" },
					"& .MuiDataGrid-columnHeaders": {
						backgroundColor: colors.blueAccent[700],
						borderBottom: "none",
					},
					"& .MuiDataGrid-virtualScroller": {
						backgroundColor: colors.primary[400],
					},
				}}
			>
				<DataGrid
					loading={isLoading || !data}
					getRowId={(row) => row?.createdAt}
					rows={(data && data.PaymentsLog) || []}
					columns={columns}
					rowsPerPageOptions={[20, 50, 100]}
					page={page}
					pageSize={pagesize}
					pagination
					paginationMode="server"
					onPageChange={(newpage) => setPage(newpage)}
					onPageSizeChange={(newpagesize) => setPageSize(newpagesize)}
					rowCount={(data && data?.total) || 0}
					components={{ Toolbar: PaymentGridToolbar }}
					componentsProps={{
						toolbar: {
							setStartDate,
							setEndDate,
							setCustomerPhone,
							setPaymentId,
							setStatus,
							setCustomerId,
							setCustomerName,
							setAmount,
						},
					}}
				/>
			</Box>
		</Box>
	);
};

export default PaymentGrid;
