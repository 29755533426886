
import { useTheme } from "@mui/material";
import { AgCharts } from "ag-charts-react";
import {tokens} from "../../../../../theme"

const WeekChart = ({ data }) => {
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);

    const weekdays = [
		"Monday",
		"Tuesday",
		"Wednesday",
		"Thursday",
		"Friday",
		"Saturday",
		"Sunday",
    ];
    const modifiedData = ()=>{
        const days = [
			"Sunday",
			"Monday",
			"Tuesday",
			"Wednesday",
			"Thursday",
			"Friday",
			"Saturday",
		];
		const today = new Date().getDay().toLocaleString();
		const currentday = days[today];

        const currentdayindex = weekdays.indexOf(currentday);

        // put sunday at the back of the list 
        const lastdate = data.slice(0, 1)
        const arrdate = data.slice(1, 7)

        return [...arrdate, ...lastdate].map((item, index)=>{
            return currentdayindex >= index
                     ? {
                        day: item?.day_name,
                        currentweek: Number(item?.current_week_revenue),
                        lastweek: Number(item?.last_week_revenue),
                     }
                     :{
                        day: item?.day_name,
                        currentweek: null,
                        lastweek: Number(item?.last_week_revenue),
                     }
        })
    }

    

	return (
		<AgCharts
			options={{
				series: [
					{
                        type:"bar",
						xKey: "day",
						yKey: "currentweek",
						yName: "This week",
					},
					{
                        type:"bar",
						xKey: "day",
						yKey: "lastweek",
						connectMissingData: false,
						yName: "Last Week",
					},
				],
				data: modifiedData(),
				title: {
					text: "Revenue this week compared with last week",
					color: colors.grey[100],
					fontSize: 25,
					fontWeight: "bold",
				},
				height: 450,
				background: {
					fill: colors.primary[400],
				},
				axes: [
					{
						type: "category",
						position: "bottom",
						label: {
							rotation: 0,
							color: colors.grey[100],
						},
						title: {
							enabled: true,
							text: "Days",
							color: colors.grey[100],
						},
					},
					{
						type: "number",
						position: "left",
						label: {
							rotation: 0,
							color: colors.grey[100],
						},
						title: {
							enabled: true,
							text: "Revenue in Ksh",
							color: colors.grey[100],
						},
					},
				],
				legend: {
					item: {
						label: {
							color: colors.grey[100],
						},
					},
				},
			}}
		/>
	);
};

export default WeekChart;
