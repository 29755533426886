import { useTheme } from "@mui/material";
import { tokens } from "../../../../../theme";
import { AgCharts } from "ag-charts-react";
const ThirtyChart = ({ data }) => {
	const modifiedata = () => {
		return data.map((item, index) => {
			return {
				day: item?.day_column.slice(5),
				revenue: Math.trunc(Number(item?.total_revenue)),
			};
		});
	};

	const theme = useTheme();
	const colors = tokens(theme.palette.mode);
	return (
		<AgCharts
			options={{
				series: [
					{
						xKey: "day",
						yKey: "revenue",
						yName: "Revenue Each day",
						stroke: colors.greenAccent[200],
						strokeWidth: 3,
						marker: {
							shape: "circle",
							size: 8,
							fill: colors.greenAccent[500],
							stroke: colors.greenAccent[200],
							strokeWidth: 2,
						},
						interpolation: { type: "smooth" },
					},
				],
				data: modifiedata(),
				title: {
					text: "Rolling 30 day revenue",
					color: colors.grey[100],
					fontSize: 25,
					fontWeight: "bold",
				},
				height: 550,
				background: {
					fill: colors.primary[400],
				},
				axes: [
					{
						type: "category",
						position: "bottom",
						label: {
							rotation: 0,
							color: colors.grey[100],
						},
						title: {
							enabled: true,
							text: "Days",
							color: colors.grey[100],
						},
					},
					{
						type: "number",
						position: "left",
						label: {
							rotation: 0,
							color: colors.grey[100],
							formatter: (params) => {
								return params.value.toLocaleString(); 
							},
						},
						title: {
							enabled: true,
							text: "Revenue in Ksh",
							color: colors.grey[100],
						},
					},
				],
				legend: {
					item: {
						label: {
							color: colors.grey[100],
						},
					},
				},
			}}
		/>
	);
};

export default ThirtyChart;
