import Header from "../../../../components/Header"
import StatBox from "../../../../components/StatBox"
import StatBoxLoading from "../../../../components/StatBoxLoading"
import { tokens } from "../../../../theme"
import { Box, useTheme,  } from "@mui/material"
import { CurrencyExchange } from "@mui/icons-material"
import SuccessFailBox from "./SuccessFailBox"

import {
	useGetTodayRevenueQuery,
	useGetThisWeekRevenueQuery,
    useGetLastWeekRevenueQuery, 
    useGetLastMonthRevenueQuery,
    useGetThisMonthRevenueQuery,
    useGetCountSucessFailedPaymentsQuery
} from "../../../../state_management/payments";

const PaymentInfoBoxes = () => {

    const {data:todayData, isLoading:todayLoading,} = useGetTodayRevenueQuery()
    const {data:thisweekData, isLoading:thisWeekLoading,} = useGetThisWeekRevenueQuery()
    const {data:lastweekData, isLoading:lastWeekLoading} = useGetLastWeekRevenueQuery()
    const {data:lastMonthData, isLoading:lastMonthLoading} = useGetLastMonthRevenueQuery()
    const {data:thisMonthData, isLoading:thisMonthLoading} = useGetThisMonthRevenueQuery()
    const {data:succesfaildata, isLoading:sucessfailLoading} = useGetCountSucessFailedPaymentsQuery()


    const theme = useTheme() 
    const colors = tokens(theme.palette.mode)

  return (
    <Box m="20px">
        <Header title={"Payments"} subtitle={"Payment Data"}/>
        <Box
            display={'grid'}
            gridTemplateColumns="repeat(12, 1fr)"
            gridAutoRows="140px"
            gap="20px"
        >
            <Box
                gridColumn="span 4"
                backgroundColor={colors.primary[400]}
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                {todayLoading || !todayData
                    ? <StatBoxLoading />
                    : <StatBox 
                        title={`${Math.trunc(Number(todayData?.total_amount)).toLocaleString()} Ksh`}
                        subtitle={`Today revenue`}
                        increase={`${todayData?.total_transactions} transactions`}
                        icon={
                            <CurrencyExchange 
                                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                            />
                        }
                    />
                }
            </Box>
            <Box
                gridColumn="span 4"
                backgroundColor={colors.primary[400]}
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                {thisWeekLoading || !thisweekData
                    ? <StatBoxLoading />
                    : <StatBox 
                        title={`${Math.trunc(Number(thisweekData?.total_amount)).toLocaleString()} Ksh`}
                        subtitle={`This Week revenue`}
                        increase={`${thisweekData?.total_transactions} transactions`}
                        icon={
                            <CurrencyExchange 
                                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                            />
                        }
                    />
                }
            </Box>
            <Box
                gridColumn="span 4"
                backgroundColor={colors.primary[400]}
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                {lastWeekLoading || !lastweekData
                    ? <StatBoxLoading />
                    : <StatBox 
                        title={`${Math.trunc(Number(lastweekData?.total_amount)).toLocaleString()} Ksh`}
                        subtitle={`Last Week revenue`}
                        increase={`${lastweekData?.total_transactions} transactions`}
                        icon={
                            <CurrencyExchange 
                                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                            />
                        }
                    />
                }
            </Box>
            <Box
                gridColumn="span 4"
                backgroundColor={colors.primary[400]}
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                {lastMonthLoading || !lastMonthData
                    ? <StatBoxLoading />
                    : <StatBox 
                        title={`${Math.trunc(Number(lastMonthData?.total_amount)).toLocaleString()} Ksh`}
                        subtitle={`Last Month's revenue`}
                        increase={`${lastMonthData?.total_transactions} transactions`}
                        icon={
                            <CurrencyExchange 
                                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                            />
                        }
                    />
                }
            </Box>
            <Box
                gridColumn="span 4"
                backgroundColor={colors.primary[400]}
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                {thisMonthLoading || !thisMonthData
                    ? <StatBoxLoading />
                    : <StatBox 
                        title={`${Math.trunc(Number(thisMonthData?.total_amount)).toLocaleString()} Ksh`}
                        subtitle={`This Month's revenue`}
                        increase={`${thisMonthData?.total_transactions} transactions`}
                        icon={
                            <CurrencyExchange 
                                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                            />
                        }
                    />
                }
            </Box>
            <Box
                gridColumn="span 4"
                backgroundColor={colors.primary[400]}
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                {sucessfailLoading || !succesfaildata
                    ? <StatBoxLoading />
                    : <SuccessFailBox 
                        successcount={succesfaildata?.filter(x => x?.payment_status ==="failure")[0]?.status_count || 0}
                        failurecount={succesfaildata?.filter(x => x?.payment_status ==="success")[0]?.status_count || 0}
                    />
                }
            </Box>
        </Box>
    </Box>
  )
}

export default PaymentInfoBoxes