import React from 'react'
import WeekChart from './WeekChart';
import { tokens } from '../../../../../theme';
import { Box, useTheme, Typography, CircularProgress } from '@mui/material';
import { useGetWeekRevenueChartQuery } from '../../../../../state_management/payments';

const WeekRevenueChart = () => {
	const { data, isLoading } = useGetWeekRevenueChartQuery();

	const theme = useTheme();
	const colors = tokens(theme.palette.mode);
	if (isLoading || !data) {
		return (
			<>
				<Box mt="25px" p="0 30px">
					<Box>
						<Typography
							variant="h3"
							fontWeight="bold"
							color={colors.greenAccent[500]}
						>
							Loading
						</Typography>
					</Box>
				</Box>
				<Box
					height="250px"
					m="-20px 0 0 0"
					display={"flex"}
					justifyContent={"center"}
					alignItems={"center"}
				>
					<CircularProgress size={50} sx={{color: colors.primary[400]}}/>
				</Box>
			</>
		);
	}
	return (
		<Box>
			{data && <WeekChart data={data?.data} />}
		</Box>
	);
}

export default WeekRevenueChart