import React from "react";
import { Search } from "@mui/icons-material";
import { useState } from "react";
import {
	Box,
	IconButton,
	InputAdornment,
	TextField,
	useTheme,
	Button,
} from "@mui/material";
import { tokens } from "../../../../theme";

const PaymentGridToolbar = ({
	setStartDate,
	setEndDate,
	setCustomerPhone,
	setPaymentId,
	setStatus,
	setCustomerId,
	setCustomerName,
	setAmount,
}) => {
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);
	const [startDateDisplay, setSatartDateDisplay] = useState("");
	const [enddateDisplay, setEndDateDisplay] = useState("");
    const [customerPhoneDisplay, setCustomerPhoneDisplay] = useState("")
    const [paymentIdDisplay, setPaymentIdDisplay] = useState("")
    const [paymentStatusDisplay, setPaymentStatusDisplay] = useState("")
    const [customerIdDisplay, setCustomerIdDisplay] = useState("")
    const [customerNameDisplay, setCustomerNameDisplay] = useState("")
    const [ammountDisplay, setAmmountDisplay]= useState("")

	return (
		<Box display={"flex"} marginBottom={"10px"} gap={"20px"}>
			{/* Start date, end date toggle */}
			<Box display={"flex"} gap={"10px"} alignItems={"center"}>
				<TextField
					label="Start Date"
					type="datetime-local"
					value={startDateDisplay}
					onChange={(e) => setSatartDateDisplay(e.target.value)}
					InputLabelProps={{
						shrink: true,
					}}
				/>
				<TextField
					label="End Date"
					type="datetime-local"
					value={enddateDisplay}
					onChange={(e) => setEndDateDisplay(e.target.value)}
					InputLabelProps={{
						shrink: true,
					}}
				/>
				<Button
					variant="Primary"
					sx={{
						backgroundColor: colors.blueAccent[400],
						color: colors.blueAccent[900],
					}}
					onClick={() => {
						const start = new Date(startDateDisplay).toISOString();
						const end = new Date(enddateDisplay).toISOString();
						setStartDate(start);
						setEndDate(end);
						setSatartDateDisplay("");
						setEndDateDisplay("");
					}}
				>
					Set dates
				</Button>
			</Box>

            {/* Phone Number Text Box */}
            <TextField 
                label="Phone"
                sx={{ width:"10rem"}}
                onChange={(e)=>setCustomerPhoneDisplay(e.target.value)}
                value={customerPhoneDisplay}                
                InputProps={{
                    endAdornment:(
                        <InputAdornment>
                            <IconButton onClick={()=>{
                                setCustomerPhone(customerPhoneDisplay)
                                setCustomerPhoneDisplay("")
                            }}>
                                <Search  />
                            </IconButton>
                        </InputAdornment>
                    )
                }}
            />


            {/* Payment id box */}
            <TextField 
                label="Payment Id"
                sx={{ width:"10rem"}}
                onChange={(e)=>setPaymentIdDisplay(e.target.value)}
                value={paymentIdDisplay}                
                InputProps={{
                    endAdornment:(
                        <InputAdornment>
                            <IconButton onClick={()=>{
                                setPaymentId(paymentIdDisplay)
                                setPaymentIdDisplay("")
                            }}>
                                <Search  />
                            </IconButton>
                        </InputAdornment>
                    )
                }}
            />

            {/* payment status */}
            <TextField 
                label="Status"
                sx={{ width:"10rem"}}
                onChange={(e)=>setPaymentStatusDisplay(e.target.value)}
                value={paymentStatusDisplay}                
                InputProps={{
                    endAdornment:(
                        <InputAdornment>
                            <IconButton onClick={()=>{
                                setStatus(paymentStatusDisplay)
                                setPaymentStatusDisplay("")
                            }}>
                                <Search  />
                            </IconButton>
                        </InputAdornment>
                    )
                }}
            />

            {/* customer Id */}
            <TextField 
                label="ID"
                sx={{ width:"10rem"}}
                onChange={(e)=>setCustomerIdDisplay(e.target.value)}
                value={customerIdDisplay}                
                InputProps={{
                    endAdornment:(
                        <InputAdornment>
                            <IconButton onClick={()=>{
                                setCustomerId(customerIdDisplay)
                                setCustomerIdDisplay("")
                            }}>
                                <Search  />
                            </IconButton>
                        </InputAdornment>
                    )
                }}
            />

            {/* Customer Name */}
            <TextField 
                label="Name"
                sx={{ width:"10rem"}}
                onChange={(e)=>setCustomerNameDisplay(e.target.value)}
                value={customerNameDisplay}                
                InputProps={{
                    endAdornment:(
                        <InputAdornment>
                            <IconButton onClick={()=>{
                                setCustomerName(customerNameDisplay)
                                setCustomerNameDisplay("")
                            }}>
                                <Search  />
                            </IconButton>
                        </InputAdornment>
                    )
                }}
            />

            {/* Ammount */}
            <TextField 
                label="Amount"
                sx={{ width:"10rem"}}
                onChange={(e)=>setAmmountDisplay(e.target.value)}
                value={ammountDisplay} 
                type="number"               
                InputProps={{
                    endAdornment:(
                        <InputAdornment>
                            <IconButton onClick={()=>{
                                setAmount(ammountDisplay)
                                setAmmountDisplay("")
                            }}>
                                <Search  />
                            </IconButton>
                        </InputAdornment>
                    )
                }}
            />
		</Box>
	);
};

export default PaymentGridToolbar;
