import { useTheme } from "@mui/material";
import { tokens } from "../../../../../theme";
import { AgCharts } from "ag-charts-react";
const FailChart = ({ data }) => {

	const theme = useTheme();
	const colors = tokens(theme.palette.mode);

    const modified =()=>{
        return data.map(x =>{
            return {...x,failure_count:Number(x.failure_count) }
        })
    }
	
	return (
		<AgCharts
			options={{
				series: [
					{
                        type:"bar",
						xKey: "failure_reason",
                        direction: 'horizontal',
						yKey: "failure_count",
						yName: "Number of Failures",
					},
				],
				data: modified(),
				title: {
					text: "Payment failure for the last 30 days",
					color: colors.grey[100],
					fontSize: 25,
					fontWeight: "bold",
				},
				height: 550,
				background: {
					fill: colors.primary[400],
				},
				axes: [
					{
						type: "number",
						position: "bottom",
						label: {
							rotation: 0,
							color: colors.grey[100],
						},
						title: {
							enabled: true,
							text: "Days",
							color: colors.grey[100],
						},
					},
					{
						type: "category",
						position: "left",
						label: {
							rotation: 0,
							color: colors.grey[100],
						},
						title: {
							enabled: true,
							text: "Payment Failure reasons",
							color: colors.grey[100],
						},
					},
				],
				legend: {
					item: {
						label: {
							color: colors.grey[100],
						},
					},
				},
			}}
		/>
	);
};

export default FailChart;
