import { Search } from "@mui/icons-material"
import { useState } from "react";
import {
    Box,
    IconButton,
    InputAdornment,
    TextField,
    useTheme, 
    Button
} from "@mui/material";
import {
    GridToolbarColumnsButton,
    GridToolbarDensitySelector,
    GridToolbarExport
} from "@mui/x-data-grid";
import { tokens } from "../../../../theme";


const SwapGridToolbar = ({setStartDate,setEndDate, setCustomerName, setBatId }) => { 

    const theme = useTheme()
    const colors = tokens(theme.palette.mode)

    const [startDateDisplay, setSatartDateDisplay] = useState("")
    const [enddateDisplay, setEndDateDisplay] = useState("")
    const [customerNameDisplay, setCustomerNameDisplay] = useState("")
    const [batteryDisplay, setBatteryDisplay] = useState("")

  return (
    <Box>
        <Box display={"flex"} marginBottom={"20px"} justifyContent={"space-between"}>

            {/* options toggle */}
            <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} sx={{
                background: colors.blueAccent[700],
                marginBottom:"-25px",
                borderRadius: "5px",
                paddingInline:"10px"
            }} >
                <GridToolbarColumnsButton/>
                <GridToolbarDensitySelector />
                <GridToolbarExport  csvOptions={{
                    fileName: `swap_info`,
                }}/>
            </Box>

            {/* Start date, end date toggle */}
            <Box display={"flex"} gap={"10px"} alignItems={"center"}>
                <TextField
						label="Start Date"
						type="datetime-local"
						value={startDateDisplay}
						onChange={(e) => setSatartDateDisplay(e.target.value)}
						InputLabelProps={{
							shrink: true,
						}}
					/>
					<TextField
						label="End Date"
						type="datetime-local"
						value={enddateDisplay}
						onChange={(e) => setEndDateDisplay(e.target.value)}
						InputLabelProps={{
							shrink: true,
						}}
					/>
					<Button
						variant="Primary"
						sx={{
							backgroundColor: colors.blueAccent[400],
							color: colors.blueAccent[900],
							paddingY: "15px",
						}}
						onClick={() => {
                            const start = new Date(startDateDisplay).toISOString()
                            const end = new Date(enddateDisplay).toISOString()
                            setStartDate(start)
                            setEndDate(end)
                            setSatartDateDisplay("")
                            setEndDateDisplay("")
                        }}
					>
						Set dates
					</Button>
            </Box>

            {/* Customer name */}
            <TextField 
                label="Customer Name"
                sx={{mb:"0.5rem", width:"15rem"}}
                onChange={(e)=>setCustomerNameDisplay(e.target.value)}
                value={customerNameDisplay}                
                InputProps={{
                    endAdornment:(
                        <InputAdornment>
                            <IconButton onClick={()=>{
                                setCustomerName(customerNameDisplay)
                                setCustomerNameDisplay("")
                            }}>
                                <Search  />
                            </IconButton>
                        </InputAdornment>
                    )
                }}
            />

            {/* Battery id */}
            <TextField 
                label="Battery Id"
                sx={{mb:"0.5rem", width:"15rem"}}
                onChange={(e)=>setBatteryDisplay(e.target.value)}
                value={batteryDisplay}                
                InputProps={{
                    endAdornment:(
                        <InputAdornment>
                            <IconButton onClick={()=>{
                                setBatId(batteryDisplay)
                                setBatteryDisplay("")
                            }}>
                                <Search  />
                            </IconButton>
                        </InputAdornment>
                    )
                }}
            />
        </Box>
    </Box>
  )
}

export default SwapGridToolbar