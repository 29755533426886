import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "../config";
import { getAuthToken } from "../utils/tokenprovider";

export const PaymentsApi = createApi({
	reducerPath: "paymentsApi",
	baseQuery: fetchBaseQuery({
		baseUrl: BASE_URL,
		prepareHeaders: (headers) => {
			const token = getAuthToken();

			if (token) {
				headers.set("authorization", `Bearer ${token}`);
			}

			return headers;
		},
	}),
	endpoints: (builder) => ({
		getCustomerPayment: builder.query({
			query: ({ customerid, startdate, enddate }) =>
				`${BASE_URL}/api/v1/payments?customerid=${customerid}&startdate=${encodeURIComponent( startdate )}&enddate=${encodeURIComponent(enddate)}`,
			transformResponse: (response) => response?.PaymentsLog,
		}),
		getTodayRevenue: builder.query({
			query: () => ({
				method: "GET",
				url: "/api/v1/payments/todayrevenue",
			}),
			transformResponse: (response) => {
				return response?.data[0];
			},
		}),
		getThisWeekRevenue: builder.query({
			query: () => ({
				method: "GET",
				url: "/api/v1/payments/thisweekrevenue",
			}),
			transformResponse: (response) => {
				return response?.data[0];
			},
		}),
		getLastWeekRevenue: builder.query({
			query: () => ({
				method: "GET",
				url: "/api/v1/payments/lastweekrevenue",
			}),
			transformResponse: (response) => {
				return response?.data[0];
			},
		}),
		getLastMonthRevenue: builder.query({
			query: () => ({
				method: "GET",
				url: "/api/v1/payments/lastmonthrevenue",
			}),
			transformResponse: (response) => {
				return response?.data[0];
			},
		}),

		getThisMonthRevenue: builder.query({
			query: () => ({
				method: "GET",
				url: "/api/v1/payments/thismonthrevenue",
			}),
			transformResponse: (response) => {
				return response?.data[0];
			},
		}),
		getCountSucessFailedPayments: builder.query({
			query: () => ({
				method: "GET",
				url: "/api/v1/payments/successfailurecount",
			}),
			transformResponse: (response) => {
				return response?.data;
			},
		}),
		getWeekRevenueChart: builder.query({
			query: () => ({
				method: "GET",
				url: "/api/v1/payments/weekrevenuechart",
			}),
		}),
		getHourlyRevenueChart: builder.query({
			query: () => ({
				method: "GET",
				url: "/api/v1/payments/hourlyrevenuechart",
			}),
		}),
		getThirtyDayRevenueChart: builder.query({
			query: () => ({
				method: "GET",
				url: "/api/v1/payments/thirtydaysrevenue",
			}),
		}),
		getFailureCountReasons: builder.query({
			query: () => ({
				method: "GET",
				url: "/api/v1/payments/failurecountreasons",
			}),
		}),
		getPayments: builder.query({
			query: ({
				startdate,
				enddate,
				page,
				pagesize,
				customerphone,
				paymentid,
				status,
				customerid,
				customername,
				amount,
			}) => ({
				method: "GET",
				url: "/api/v1/payments",
				params: {
					startdate,
					enddate,
					customername,
					page: page + 1,
					limit: pagesize,
					customerphone,
					paymentid,
					status,
					customerid,
					amount,
				},
			}),
		}),
	}),
});

export const {
	useGetCustomerPaymentQuery,
	useGetTodayRevenueQuery,
	useGetThisWeekRevenueQuery,
	useGetLastWeekRevenueQuery,
	useGetLastMonthRevenueQuery,
	useGetThisMonthRevenueQuery,
	useGetCountSucessFailedPaymentsQuery,
	useGetWeekRevenueChartQuery,
	useGetHourlyRevenueChartQuery,
	useGetThirtyDayRevenueChartQuery,
	useGetFailureCountReasonsQuery,
	useGetPaymentsQuery,
} = PaymentsApi;
