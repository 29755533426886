import { Box } from "@mui/material";
import PaymentGrid from "./paymentdatagrid/PaymentGrid";
import PaymentInfoBoxes from "./topsection/PaymentInfoBoxes";
import PaymentCharts from "./charts/PaymentCharts";

const Payments = () => {


  return (
    <Box>
      <PaymentInfoBoxes />
      <PaymentCharts />
      <PaymentGrid />
    </Box>
  );
};

export default Payments;
