import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../../../../theme";
import { CheckCircleOutline } from "@mui/icons-material";
import { CancelOutlined } from "@mui/icons-material";

const SuccessFailBox = ({ successcount, failurecount }) => {
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);

	return (
		<Box width="100%" m="0 30px">
            <Box display="flex" justifyContent="space-between">
                

				<Typography
					variant="h4"
					sx={{ color: colors.greenAccent[500] }}
				>
					{`${failurecount} Successful transactions in the last 24 hours`}
				</Typography>
                <CheckCircleOutline />
			</Box>
            <Box mt={"10px"} mb={"10px"}></Box>
			<Box display="flex" justifyContent="space-between">
            
				<Typography
					variant="h4"
					sx={{ color: colors.greenAccent[500] }}
				>
					{`${successcount} Failed transactions in the last 24 hours`}
				</Typography>
				<CancelOutlined />
			</Box>
		</Box>
	);
};

export default SuccessFailBox;
