import { Box, Button, useTheme } from "@mui/material"
import { tokens } from "../../../../theme"
import { useNavigate } from "react-router-dom"
import { DataGrid } from "@mui/x-data-grid"
import { useGetBatteriesQuery } from "../../../../state_management/batteries"
import { useState } from "react"
import BatteryGridToolbar from "./BatteryGridToolbar"
import GetlastDeposited from "./GetlastDeposited"
import GetlastDispatched from "./GetLastDispatched"

const BatteriesDatgrid = () => {

    const theme = useTheme()
    const colors = tokens(theme.palette.mode)
    const navigate = useNavigate()

    const [page, setPage] = useState(0)
    const [limit, setLimit] = useState(50)
    const [battpoolid, setBattpoolid] = useState("")
    const [batpoolsearchdisplay, setBatPoolSearchDisplay] = useState("")


    const {data, isLoading} = useGetBatteriesQuery({
        page,
        pagesize:limit, 
        batterypoolid: battpoolid
    })

    const viewBatteryDetails = (batteryId, bpoolid) =>{
        navigate(`/batteries/${batteryId}`, {state:{bpoolid}})
      }

    const columns = [
        {
          field: "id_with_batterypool",
          headerName: "BATTERYPOOL ID",
          cellClassName: "name-column--cell",
          flex : 1
        },
        {
          field: "macid",
          headerName: "MAC ID",
          cellClassName: "name-column--cell",
        },
        {
          field: "dispatched",
          headerName: "LAST DISPATCHED",
          headerAlign: "center",
          flex: 1,
          renderCell: ({ row }) => {
            return (
              <GetlastDispatched batterypoolid={row?.id_with_batterypool}/>
            );
          },
        },
        {
          field: "deposited",
          headerName: "LAST DEPOSITED",
          headerAlign: "center",
          flex: 1,
          renderCell: ({ row }) => {
            return (
              <GetlastDeposited batterypoolid={row?.id_with_batterypool}/>
            );
          },
        },
        {
          field: "view",
          headerName: "VIEW DETAILS",
          flex: 1,
          renderCell: ({ row }) => {
            return (
              <Button
                variant='Primary'
                sx={{
                  backgroundColor:colors.blueAccent[400],
                  color: colors.blueAccent[900],
                }}
                onClick={()=> {viewBatteryDetails(row?.macid, row?.id_with_batterypool)}}
              >
                  View Battery Details
              </Button>
            );
          },
        },
      ];
  return (
    <Box marginTop={"20px"}>
        <Box
            height={'70vh'}
            sx={{
                "& .MuiDataGrid-root":{border:"none"},
                "& .MuiDataGrid-cell":{borderBottom:"none"},
                "& .MuiDataGrid-columnHeaders": {
                    backgroundColor: colors.blueAccent[700],
                    //color: theme.palette.secondary[100],
                    borderBottom: "none",
                    },
                "& .MuiDataGrid-virtualScroller": {
                backgroundColor: colors.primary[400],
                },
            }}
        >
            <DataGrid 
                loading={isLoading || !data}
                getRowId={(row) => row?.battery_id}
                columns={columns}
                rows={(data && data.Batteries) || []}
                rowsPerPageOptions={[20, 50, 100]}
                page={page}
                pageSize={limit}
                pagination
                paginationMode="server"
                onPageChange={(newpage) => setPage(newpage)}
                onPageSizeChange={(newpagesize)=> setLimit(newpagesize)}
                rowCount={(data && data?.total) || 0}
                components={{Toolbar: BatteryGridToolbar}}
                componentsProps={{toolbar:{batpoolsearchdisplay, setBatPoolSearchDisplay, setBattpoolid}}}

            />
        </Box>
    </Box>
  )
}

export default BatteriesDatgrid