import { Box } from '@mui/material'
import FailureCountChart from './failurecountchart/FailureCountChart'
import HourlyRevenueChart from './hourlyrevenuechart/HourlyRevenueChart'
import ThirtyDayRevenueChart from './thirtydayrevenuechart/ThirtyDayRevenueChart'
import WeekRevenueChart from './weekrevenuechart/WeekRevenueChart'


const PaymentCharts = () => {
  return (
    <Box
        display={"grid"}
        gridTemplateColumns={"repeat(12, 1fr)"}
        gridAutoRows="140px"
        gap="30px"
        sx={{
            marginTop: "20px",
            marginBottom: "40px",
            marginInline:"20px",
        }}
    >
        <Box gridColumn="span 6" gridRow="span 3">
            <HourlyRevenueChart />
        </Box>
        <Box gridColumn="span 6" gridRow="span 3">
            <WeekRevenueChart />
        </Box>
        <Box gridColumn="span 12" gridRow="span 3">
            <ThirtyDayRevenueChart />
        </Box>
        <Box gridColumn="span 12" gridRow="span 3">
            <FailureCountChart />
        </Box>
    </Box>
  )
}

export default PaymentCharts