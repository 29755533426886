import { Box, useTheme, Button, Typography } from "@mui/material";
import { tokens } from "../../../../theme";
import { DataGrid } from "@mui/x-data-grid";
import { useGetSwapsQuery } from "../../../../state_management/swapapi";
import { useEffect, useState } from "react";
import { formatDateString } from "../../../../utils/dateUtils";
import SwapsPaymentCheck from "../../operationscomponents/SwapsPaymentCheck2";
import ClearIcon from "@mui/icons-material/Clear";
import DoneIcon from "@mui/icons-material/Done";
import SwapGridToolbar from "./SwapGridToolbar";
import { formatSwapData } from "../../../../utils/FormatSwapGridData";


const SwapsGrid = () => {
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);

	const [startdate, setStartDate] = useState("");
	const [enddate, setEndDate] = useState("");
	const [batid, setBatId] = useState("");
	const [customername, setCustomerName] = useState("");
	const [page, setPage] = useState(0);
	const [pagesize, setPageSize] = useState(50);
	// eslint-disable-next-line
	const [swapstatus, setSwapStatus] = useState("swap_complete")

	const [loading, setLoading] = useState(false)
	const [swapData, setSwapData]= useState(null)

	const { data } = useGetSwapsQuery({
		startdate,
		enddate,
		batid,
		customername,
		page,
		pagesize,
		swapstatus
	});


	useEffect(()=>{

		const getTransformedSwapData = async() =>{
			const swapdatawithcontext = await formatSwapData(data?.SwapsLog)
			return swapdatawithcontext
		}

		const fetchTransformedData = async()=>{
			
			try {
				if(data){
					setLoading(true)
					setSwapData(data?.SwapsLog)
					const transformedData = await getTransformedSwapData();
					console.log("TRANSFORMED SWAP DATA", transformedData)
					setSwapData(transformedData)
		
				}
			} catch (error) {
				console.error("Error fetching transformed swap data:", error);
			}finally{
				setLoading(false)
			}
		}

		fetchTransformedData()

	},[data ])
	

	const columns = [
		{
			field: "customer_name",
			headerName: "CUSTOMER",
			flex: 1,
			renderCell: ({ row: { customer_id, customer_name } }) => {
				return (
					<Button
						variant="Primary"
						sx={{
							backgroundColor: colors.blueAccent[500],
							color: colors.blueAccent[900],
						}}
						onClick={() => console.log(customer_id)}
					>
						{customer_name}
					</Button>
				);
			},
		},
		{
			field: "provider_timestamp",
			headerName: "TIME",
			flex: 0.8,
			cellClassName: "name-column--cell",
			align: "center",
			headerAlign: "center",
			renderCell: ({ row: { provider_timestamp } }) => {
				return `${formatDateString(provider_timestamp)}`;
			},
			valueFormatter:  (value) => {
				return `${formatDateString(value.value)}`;
			},
		},
		{
			field: "deposited_battery_id",
			headerName: "DEPOSITED BAT",
			flex: 0.8,
		},
		{
			field: "dispatched_battery_id",
			headerName: "DISPATCHED BAT",
			flex: 0.8,
			cellClassName: "name-column--cell",
			align: "center",
			headerAlign: "center",
		},
		{
			field: "cabinet_name",
			headerName: "CABINETNAME",
			flex: 0.6,
			renderCell: ({ row :{cabinet_name} }) => {
				return cabinet_name ? cabinet_name : "loading..."
			}
			// renderCell: ({ row }) => {
			// 	return <CabinetName cabinetbpoolid={row?.cabinet_id} />;
			// },
			// valueGetter: ({ row }) => row?.cabinet_id,
			// valueFormatter:  (value) => {
			// 	return value.value;
			// },
		},
		{
			field: "corresponding_customer_payment",
			headerName: "PAYMENT RECORDED",
			flex: 2,
			cellClassName: "name-column--cell",
			headerAlign: "center",
			renderCell: ({ row :{payment_data} }) => {
				return  payment_data ?  <SwapsPaymentCheck payments={payment_data} /> : "...loading";
			},
			valueGetter: ({ row }) => row?.payment_data,
			valueFormatter: (value) =>{
				const payments = value.value

				const sucessfulPaymentDetails = payments?.filter(x => x.payment_status === "success")[0] || null
				return `${
							sucessfulPaymentDetails 
							? `Paid ${sucessfulPaymentDetails?.amount} on ${formatDateString(sucessfulPaymentDetails?.createdAt)} with phone number ${sucessfulPaymentDetails?.phone}`
							: "NO SUCCESSFUL PAYMENT RECORDED AT TIME OF THIS SWAP"
						}`;
			}
		},
		{
			field: "swap_status",
			headerName: "SWAP STATUS",
			flex: 1,
			cellClassName: "name-column--cell",
			align: "center",
			headerAlign: "center",
			renderCell: ({ row: { swap_status } }) => {
				return (
					<Box
						width="60%"
						// m="0 auto"
						p="5px"
						display="flex"
						justifyContent="center"
						backgroundColor={
							swap_status === "swap_complete"
								? colors.greenAccent[600]
								: colors.greenAccent[700]
						}
						borderRadius="4px"
					>
						{swap_status === "swap_complete" ? (
							<DoneIcon />
						) : (
							<ClearIcon />
						)}
						<Typography color={colors.grey[100]} sx={{ ml: "5px" }}>
							{swap_status === "swap_complete"
								? "Sucessful"
								: "Unsucessful"}
						</Typography>
					</Box>
				);
			},
		},

	];

	return (
		<Box>
			<Typography
				variant="h3"
				fontWeight="bold"
				mb={"10px"}
				color={colors.greenAccent[500]}
			>
				Last 50 swaps
			</Typography>
			<Box
				height="75vh"
				sx={{
					"& .MuiDataGrid-root": { border: "none" },
					"& .MuiDataGrid-cell": { borderBottom: "none" },
					"& .MuiDataGrid-columnHeaders": {
						backgroundColor: colors.blueAccent[700],
						borderBottom: "none",
					},
					"& .MuiDataGrid-virtualScroller": {
						backgroundColor: colors.primary[400],
					},
				}}
			>
				<DataGrid
					loading={loading || !swapData}
					getRowId={(row) => row?.createdAt}
					rows={(data && swapData) || []}
					columns={columns}
					rowsPerPageOptions={[20, 50, 100]}
					page={page}
					pageSize={pagesize}
					pagination
					paginationMode="server"
					onPageChange={(newpage) => setPage(newpage)}
					onPageSizeChange={(newpagesize) => setPageSize(newpagesize)}
					rowCount={(data && data?.total) || 0}
					components={{Toolbar: SwapGridToolbar}}
					componentsProps={{toolbar:{setStartDate, setEndDate, setCustomerName, setBatId}}}
				/>
			</Box>
		</Box>

	);
};

export default SwapsGrid;
