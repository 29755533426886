import {useEffect} from 'react'
import { Box, Typography, useTheme, Button } from '@mui/material'
import { tokens } from '../../../theme'
import { DataGrid } from '@mui/x-data-grid';
import ClearIcon from '@mui/icons-material/Clear';
import DoneIcon from '@mui/icons-material/Done';
import Header from '../../../components/Header';
import StatBox from '../../../components/StatBox';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import PublicIcon from '@mui/icons-material/Public';
import TwoWheeler from "@mui/icons-material/TwoWheeler";
import { useNavigate } from 'react-router-dom';
import StatBoxLoading from '../../../components/StatBoxLoading';
import { useSelector, useDispatch } from 'react-redux';
import { fetchTodaySwaps, fetchRevenueToday, fetchCabinets } from '../../../state_management/cabinetDashboard';


const CabinetHomepage = () => {

  const dispatch = useDispatch()
  const {swaps, revenue, cabinetdata} = useSelector(state =>state.cabinets)

  // console.log("CABINETDATAAAA", cabinetdata?.data?.raw)

  const navigate = useNavigate()
  const viewCabinetDetails = (cabinetid, info) =>{
    //console.log(`operations/cabinets/${cabinetid}`)
    navigate(`/cabinets/${cabinetid}`, {state:{info}})
  }

  const theme = useTheme() // grab  theme from material ui context, set in layout.js 
  const colors = tokens(theme.palette.mode)

  useEffect(()=>{
    dispatch(fetchTodaySwaps())
    dispatch(fetchRevenueToday())
    dispatch(fetchCabinets())
  },[dispatch])


  const columns = [
    { field: "cabinet_id", headerName: "ID", flex: 1, },
    {
      field: "display_name",
      headerName: "LOCATION",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "cabinet_data",
      headerName: "CABINET STATUS", // active inactive
      flex: 1,
      renderCell: ({ row: { cabinet_data } }) => {
        return (
          <Box
            width="60%"
            // m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            backgroundColor={
              cabinet_data?.device_status === 0
                ? colors.greenAccent[600]
                : cabinet_data?.device_status === 1
                ? colors.redAccent[500]
                : colors.greenAccent[700]
            }
            borderRadius="4px"
          >
            {cabinet_data?.device_status === 0 === "active" && <DoneIcon />}
            {cabinet_data?.device_status === 1 === "inactive" && <ClearIcon />}
            <Typography color={colors.grey[100]} sx={{ ml: "5px" }}>
              {cabinet_data?.device_status ===  0 ? "Active" :"Inactive"}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: "swapsToday",
      headerName: "SWAPS TODAY",
      flex: 1,
      cellClassName: "name-column--cell",
      align:"center",
      headerAlign: "center",
      renderCell: ({row:{swapsToday}})=>{
        return swapsToday?.length
      }
    },
    {
      field: "energyUsed",
      headerName: "ENERGY USED TODAY",
      flex: 1,
      cellClassName: "name-column--cell",
      align:"center",
      headerAlign: "center",
      renderCell: ({row:{energyUsed}})=>{
        return Math.floor(energyUsed)
      }
    },
    {
      field: "energy_cost",
      headerName: "ENERGYCOST",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "view",
      headerName: "VIEW DETAILS",
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <Button
            variant='Primary'
            sx={{
              backgroundColor:colors.blueAccent[400],
              color: colors.blueAccent[900],
            }}
            onClick={()=> viewCabinetDetails(row?.cabinet_id, row)}
          >
              View Details
          </Button>
        );
      },
    },
  ];
  return (
    <Box m="20px">
    <Header title="Cabinets" subtitle="Powerhive Cabinets" />
    <Box 
      display={'grid'}
      gridTemplateColumns="repeat(12, 1fr)"
      gridAutoRows="140px"
      gap="20px"
    >
      {/* first row */}
      <Box
          gridColumn="span 4"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          {
            swaps?.loading || !swaps?.data 
              ? <StatBoxLoading />
              : swaps?.error
              ? <StatBoxLoading />
              :   <StatBox
                    title={swaps?.data?.swapsCompleted}
                    subtitle="Swaps completed today"
                    increase={swaps?.data?.swapsCompleted}
                    icon={
                      <TwoWheeler
                        sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                      />
                    }
                />
          }

        </Box>
        <Box
          gridColumn="span 4"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          {
            cabinetdata?.loading || !cabinetdata?.data 
              ? <StatBoxLoading />
              : cabinetdata?.error
              ? <StatBoxLoading />
              :   <StatBox
                    title={Math.floor(cabinetdata?.data?.energyUsedToday)}
                    subtitle="Energy used today in kwh"
                    increase={Math.floor(cabinetdata?.data?.energyUsedToday)}
                    icon={
                      <PublicIcon
                        sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                      />
                    }
                />
          }
        </Box>
        <Box
          gridColumn="span 4"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          {
            revenue?.loading || !revenue?.data 
              ? <StatBoxLoading />
              : revenue?.error
              ? <StatBoxLoading />
              :   <StatBox
                    title={revenue?.data?.paymentToday}
                    subtitle="Revenue generated today"
                    increase={revenue?.data?.paymentToday}
                    icon={
                      <AttachMoneyIcon
                        sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                      />
                    }
                />
          }
        </Box>
    </Box>
    {
      cabinetdata?.data && (
        <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <DataGrid rows={cabinetdata?.data?.raw} columns={columns} />
      </Box>
      )
    }
  </Box>
  )
}

export default CabinetHomepage