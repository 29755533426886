import { useTheme } from "@mui/material";
import { AgCharts } from "ag-charts-react";
import { tokens } from "../../../../../theme";

const HourChart = ({ data }) => {
	//console.log("Hour chart data >>>>", data);
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);

	const modifiedData = () => {
		return data.map((item, index) => {
			return {
				hour: item?.hour.slice(0, 2),
				yesterday: Math.trunc(Number(item?.yesterday_revenue)),
				today:
					item?.today_revenue === null
						? null
						: Math.trunc(Number(item?.today_revenue)),
			};
		});
	};

	return (
		<AgCharts
			options={{
				series: [
					{
						xKey: "hour",
						yKey: "yesterday",
						yName: "Yesterday Revenue",
						stroke: colors.greenAccent[200],
						strokeWidth: 3,
						marker: {
							shape: "circle",
							size: 8,
							fill: colors.greenAccent[500],
							stroke: colors.greenAccent[200],
							strokeWidth: 2,
						},
						interpolation: { type: "smooth" },
					},
					{
						xKey: "hour",
						yKey: "today",
						connectMissingData: false,
						yName: "Today Revenue",
						stroke: colors.blueAccent[200],
						strokeWidth: 3,
						marker: {
							shape: "cross",
							size: 8,
							fill: colors.blueAccent[500],
							stroke: colors.blueAccent[200],
							strokeWidth: 2,
						},
						interpolation: { type: "smooth" },
					},
				],
				data: modifiedData(),
				title: {
					text: "Yesterday and today revenue trend",
					color: colors.grey[100],
					fontSize: 25,
					fontWeight: "bold",
				},
				height: 450,
				background: {
					fill: colors.primary[400],
				},
				axes: [
					{
						type: "category",
						position: "bottom",
						label: {
							rotation: 0,
							color: colors.grey[100],
						},
						title: {
							enabled: true,
							text: "Hours of the day",
							color: colors.grey[100],
						},
					},
					{
						type: "number",
						position: "left",
						label: {
							rotation: 0,
							color: colors.grey[100],
						},
						title: {
							enabled: true,
							text: "Revenue in Ksh",
							color: colors.grey[100],
						},
					},
				],
				legend: {
					item: {
						label: {
							color: colors.grey[100],
						},
					},
				},
			}}
		/>
	);
};

export default HourChart;
